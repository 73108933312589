export const MutationExpandMenu = 'MutationExpandMenu';
export const MutationLang = 'MutationLang';
export const MutationLoading = 'MutationLoading';
export const MutationModal = 'MutationModal';
export const MutationModalShow = 'MutationModalShow';
export const MutationPatientData = 'MutationPatientData';
export const MutationScreenSize = 'MutationScreenSize';
export const MutationSidebarControl = 'MutationSidebarControl';
export const MutationToggleDevice = 'MutationToggleDevice';
export const MutationToggleSidebar = 'MutationToggleSidebar';
export const MutationUser = 'MutationUser';
export const MutationVersion = 'MutationVersion';
export const MutationWebSocket = 'MutationWebsocket';
export const MutationFrontendSettings = 'MutationFrontendSettings';
export const MutationFrontendWebsiteunit = 'MutationFrontendWebsiteunit';
export const MutationMenuData = 'MutationMenuData';
export const MutationLangList = 'MutationLangList';
export const MutationLangTranslation = 'MutationLangTranslation';
export const MutationShopCartList = 'MutationShopCartList';
export const MutationShopCartOrderList = 'MutationShopCartOrderList';
export const MutationCurrencyType = 'MutationCurrencyType';
export const MutationCurrencyTypeList = 'MutationCurrencyTypeList';
export const MutationWebsiteEdit = 'MutationWebsiteEdit';
