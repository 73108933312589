import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue)
import App from './App';
import Router from 'vue-router'
// import router from './router';
Vue.use(Router)

import { ClsRoute } from '@/wam/modules/route';

const comp_defaultcontainer = () => import('@/containers/defaultcontainer');
const comp_login = () => import('@/wam/views/login');
const comp_register = () => import('@/wam/views/register');
const comp_logout = () => import('@/wam/views/logout');
const comp_register_approve = () => import('@/wam/views/register_approve');
const comp_login_password_reset = () => import('@/wam/views/login_password_reset');
import store from './store';
import { sync } from 'vuex-router-sync';
import Resource from 'vue-resource';
Vue.use(Resource);
// ------- LANG ------------
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
import wdm16 from './wdm16';
let vue_i18n_data = {
  'locale': 'wdm16',
  'fallbackLocale': 'wdm16',
  'messages': { 'wdm16': wdm16 }
};
const i18n = new VueI18n(vue_i18n_data);
// ------- LANG ------------

import * as filters from './filters';
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
// import { data as GeneralConfig } from '@/wam/config/GeneralConfig';
// console.log('GeneralConfig : ', GeneralConfig);
var frontend_product_id = '';
// let frontend_product_url = '';
// try {
//   frontend_product_id = GeneralConfig['data']['general']['frontend_product_id']['val'];
// } catch (err) {}
// console.log('frontend_product_id : ', frontend_product_id);
// market.wisdomera.io => 6a82ddf7-3e12-4708-8ad5-0335a66d4b23
// wisdomera.io => 6a82ddf7-3e12-4708-8ad5-0335a66d4b23
// docs.wisdomera.io => asldofuaosdjvlkadsflajsd39
// frontend_product_id = '6a82ddf7-3e12-4708-8ad5-0335a66d4b23';

const { state } = store;
import VueNativeSock from 'vue-native-websocket';
import auth from './auth';
auth.checkAuth();

Vue.http.interceptors.push(function(request, next) {
  // Add Authorization to all requests
  request.headers.set('Authorization', auth.getAuthHeader());
  var user_currency_type = localStorage.getItem('user_currency_type');
  if (user_currency_type) {
    var u_cur_type = JSON.parse(user_currency_type);
    request.params.__user__currency__type = u_cur_type.value;
  }
  var localstorage_active_lang = localStorage.getItem('active_lang');
  if (localstorage_active_lang) {
    var use_active_lang = JSON.parse(localstorage_active_lang);
    request.params.__lang__v = use_active_lang.value;
    request.params.__lang__l = use_active_lang.label;
  }
  if (frontend_product_id) {
    request.params.__sys__pro__id = frontend_product_id;
  }
  if (request.url !== 'api/token/refresh') {}
  // Check for expired token response, if expired, refresh token and resubmit original request
  next(function(response) {}, response => {
    console.log('http request error: ', response)
  });
}.bind(this)); 

const IS_LOCAL = process.env.NODE_ENV !== 'production';
if (IS_LOCAL) {
  Vue.config.devtools = true;
}
var all_language_options = [];
import { data as frontend_settings } from '@/wam/options/frontend_settings';
import { data as lang_list } from '@/wam/options/lang_list';
import { data as currency_type_list } from '@/wam/options/currency_type_list';
import { data as frontend_websiteunit } from '@/wam/options/frontend_websiteunit';
import { data as lang_translation } from '@/wam/options/lang_translation';
// console.log('frontend_websiteunit ', frontend_websiteunit);
// import { data as system_menu_list } from '@/wam/options/system_menu_list';

const system_menu_list = ClsRoute.f_createSystemMenuData();
// console.log('system_menu_list: ', system_menu_list);

import { ServiceFunctions } from '@/wam/modules/service';

f_frontendGet();

// function f_defaultFrontend() {
//   let target_frontend_settings = f_createFrontendSettings(frontend_settings);
//   store.commit('MutationFrontendSettings', { 'status': 1, 'data': target_frontend_settings });
//   if (frontend_websiteunit.length > 0) {
//     var menu_data = f_prepareMenuData();
//     store.commit('MutationMenuData', { 'status': 1, 'data': menu_data });
//     let routes = [{
//       path: '/',
//       // redirect: '/welcome',
//       name: 'defaultcontainer',
//       component: comp_defaultcontainer,
//       children: [
//         ...f_createRouteList(menu_data),
//       ]
//     }];
//     if (target_frontend_settings.login_function === '1') {
//       routes.push({ path: '/frontendsettings', name: 'frontendsettings', component: comp_frontend_settings });
//       routes.push({ path: '/login', name: 'login', component: comp_login });
//       routes.push({ path: '/logout', name: 'logout', component: comp_logout });
//       routes.push({ path: '/register/approve', name: 'register_approve', component: comp_register_approve });
//       routes.push({ path: '/kayit/onay', name: 'register_approve', component: comp_register_approve });
//       if (target_frontend_settings.login_function_external !== '1') {
//         routes.push({ path: '/register', name: 'register', component: comp_register });
//         routes.push({ path: '/login/password_reset', name: 'login_password_reset', component: comp_login_password_reset });
//         routes.push({ path: '/giris/sifre-sifirlama', name: 'login_password_reset', component: comp_login_password_reset });
//       }
//     }
//     var router = f_createVueRouter(routes);
//     sync(store, router);
//     router.beforeEach((to, from, next) => {
//       var access_token = localStorage.getItem('access_token');
//       var ws_url = '';
//       next();
//     });
//     var app = new Vue({
//       el: '#app',
//       router,
//       i18n,
//       store,
//       template: '<App/>',
//       components: {
//         App
//       }
//     });
//   }
// }

function f_frontendGet() {
  // aşağıda servisten frontend ayarları güncelleme yapılıyor. Dolayısıyla sayfa yenilendiğinde siliniyor.
  try {
    localStorage.removeItem('frontend_settings');
  } catch(err) {}
  let location_url = window.location.host;
  // console.log('window.location : ', window.location);
  // console.log('location_url    : ', location_url);
  try {
    location_url = location_url.replace(/^wwww\./g, "");
  } catch (err) {}
  // console.log('location_url : ', location_url);
  let query = '';
  let data = {
    'domain': location_url
  };

  // console.log('ServiceFunctions : ', ServiceFunctions);
  // console.log('ServiceFunctions.frontend_get : ', ServiceFunctions.frontend_get);

  ServiceFunctions.frontend_get(query, data)
    .then(resp => {
      // console.log('resp.data ', resp.data);
      if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
        if (resp.data.frontend) {
          let web_unit_list = resp.data.website_unit_list;
          let f_settings = resp.data.frontend;
          frontend_product_id = resp.data.frontend_product_id;
          localStorage.setItem('system_product_id', frontend_product_id);
          store.commit('MutationLangTranslation', { 'status': 1, 'data': lang_translation });
          store.commit('MutationCurrencyTypeList', { 'status': 1, 'data': currency_type_list });
          store.commit('MutationFrontendSettings', { 'status': 1, 'data': f_settings });
          let local_storage_f_settings = {
            'application_name': f_settings.application_name,
            'navbar_middle_logo': f_settings.navbar_middle_logo,
            'page_favicon': f_settings.page_favicon,
            'language_options': f_settings.language_options,
          };
          localStorage.setItem('frontend_settings', JSON.stringify(local_storage_f_settings));

          let query_2 = '';
          let data_2 = {
            'layer_code_list': ['frd']
          };

          ServiceFunctions.layer_wdm_get(query_2, data_2)
            .then(resp_2 => {
              // console.log('resp_2.data ', resp_2.data);
              if (resp_2.data.status_code.substring(resp_2.data.status_code.length - 3, resp_2.data.status_code.length) == '000') {
                if (resp_2.data.layer_wdm_list) {
                  if (!localStorage.getItem('layer_wdm_list')) {
                    localStorage.setItem('layer_wdm_list', JSON.stringify(resp_2.data.layer_wdm_list));
                  }
                  // f_layerWdmGet();
                  var d_frdWdm = resp_2.data.layer_wdm_list.frd;
                  all_language_options = d_frdWdm['0']['parameters']['87']['options'];
                  // console.log('all_language_options : ', all_language_options);

                  // console.log('language_options : ', f_settings.language_options);
                  // Burada sistemden alınan değerlerle store yapısı yönetilebilir. Bazı bilgiler options içerisinden bazı bilgiler de hard code eklendi.
                  let default_lang = 'en';
                  if (f_settings.default_language) {
                    for (let lang of all_language_options) {
                      if (lang.value === f_settings.default_language) {
                        default_lang = lang.label;
                        break;
                      }
                    }
                  }
                  let use_lang_list = [];
                  for (let lang of all_language_options) {
                    if (f_settings.language_options.indexOf(lang.value) !== -1) {
                      use_lang_list.push(lang);
                    }
                  }
                  store.commit('MutationLangList', { 'status': 1, 'data': use_lang_list });
                  store.commit('MutationLang', default_lang);

                  // let menu_data = f_prepareMenuData();
                  let menu_data = { 'list': [] };
                  if (web_unit_list) {
                    menu_data['list'] = web_unit_list;
                  }

                  for (let i in system_menu_list) {
                    /*
                    let new_menu_item = {
                      'route_name': system_menu_list[i].route_name,
                      'route_path': system_menu_list[i].route_path,
                      'component_name': system_menu_list[i].component_name,
                      'caption': system_menu_list[i].caption,
                      'list': system_menu_list[i].list,
                    };
                    menu_data['list'].push(new_menu_item);
                    */
                    menu_data['list'].push(system_menu_list[i]);
                  }
                  // console.log('menu_data : ', menu_data);
                  store.commit('MutationMenuData', { 'status': 1, 'data': menu_data });
                  let routes = [];
                  let first_route = {
                    path: '/',
                    // redirect: '/welcome',
                    name: 'defaultcontainer',
                    component: comp_defaultcontainer,
                    children: [
                      ...f_createRouteList(menu_data),
                    ]
                  };
                  let redirect_dashboard_path = f_getRedirectDashboardRoute(menu_data);
                  // console.log('redirect_dashboard_path : ', redirect_dashboard_path);
                  if (redirect_dashboard_path) {
                    first_route['redirect'] = redirect_dashboard_path;
                  }
                  routes.push(first_route);
                  if (f_settings.login_function === '1') {
                    routes.push({ path: '/login', name: 'login', component: comp_login });
                    routes.push({ path: '/logout', name: 'logout', component: comp_logout });
                    routes.push({ path: '/register/approve', name: 'register_approve', component: comp_register_approve });
                    routes.push({ path: '/kayit/onay', name: 'register_approve', component: comp_register_approve });
                    if (f_settings.login_function_external !== '1') {
                      routes.push({ path: '/register', name: 'register', component: comp_register });
                      routes.push({ path: '/login/password_reset', name: 'login_password_reset', component: comp_login_password_reset });
                      routes.push({ path: '/giris/sifre-sifirlama', name: 'login_password_reset', component: comp_login_password_reset });
                    }

                  }
                  var router = f_createVueRouter(routes);
                  sync(store, router);
                  router.beforeEach((to, from, next) => {
                    var access_token = localStorage.getItem('access_token');
                    var ws_url = '';
                    next();
                  });
                  auth.f_updateRouter(router);
                  var app = new Vue({
                    el: '#app',
                    router,
                    i18n,
                    store,
                    template: '<App/>',
                    components: {
                      App
                    }
                  });


                }
              } else {
                alert(resp_2.data.status_code + ' = ' + resp_2.data.status_message);
              }
            }), resp_2 => {
              console.log('error');
            };

        }
      } else {
        alert(resp.data.status_code + ' = ' + resp.data.status_message);
      }
    }), resp => {
      console.log('error');
    };
};


function f_layerWdmGet() {
  // localStorage.removeItem('layer_wdm_list');
  let query = '';
  let data = {
    'layer_code_list': []
  };

  ServiceFunctions.layer_wdm_get(query, data)
    .then(resp => {
      // console.log('resp.data ', resp.data);
      if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
        if (resp.data.layer_wdm_list) {
          localStorage.setItem('layer_wdm_list', JSON.stringify(resp.data.layer_wdm_list));
        }
      } else {
        alert(resp.data.status_code + ' = ' + resp.data.status_message);
      }
    }), resp => {
      console.log('error');
    };
};


function f_prepareMenuData() {
  let i = 0;
  let menu_data = { 'list': [] };
  let parent_item_index_list = {};
  while (1) {
    let there_is_not_completed = false;
    // console.log('i : ', i);
    if (i < frontend_websiteunit.length) {
      let status = '';
      try {
        status = frontend_websiteunit[i]['data']['0']['0']['val']['value'];
      } catch (err) {}
      if (status == '1') {
        let new_menu_item = {};
        let website_unit_no = '';
        let menu_caption = '';
        let route_path = '';
        let route_name = '';
        let link = '';
        try {
          menu_caption = frontend_websiteunit[i]['data']['0']['2']['val'];
        } catch (err) {}
        try {
          route_name = frontend_websiteunit[i]['data']['0']['25']['val'];
        } catch (err) {}
        try {
          route_path = frontend_websiteunit[i]['data']['0']['4']['val'];
        } catch (err) {}
        try {
          link = frontend_websiteunit[i]['data']['0']['13']['val'];
        } catch (err) {}

        new_menu_item['caption'] = menu_caption
        new_menu_item['route_path'] = route_path
        new_menu_item['route_name'] = route_name
        new_menu_item['link'] = link

        try {
          website_unit_no = frontend_websiteunit[i]['data']['0']['23']['val'];
        } catch (err) {}
        // console.log('website_unit_no : ', website_unit_no);
        let parent_website_unit_no = '';
        try {
          parent_website_unit_no = frontend_websiteunit[i]['data']['0']['24']['val'];
        } catch (err) {}
        if (parent_website_unit_no) {
          if (parent_item_index_list[parent_website_unit_no]) {
            let parent_item_ind = parent_item_index_list[parent_website_unit_no];
            if (!menu_data['list'][parent_item_ind].list) {
              menu_data['list'][parent_item_ind].list = [];
            }
            menu_data['list'][parent_item_ind].list.push(new_menu_item);
          } else {
            there_is_not_completed = true;
          }
        } else {
          if (website_unit_no) {
            menu_data['list'].push(new_menu_item);
            parent_item_index_list[website_unit_no] = menu_data['list'].length - 1
          }
        }
      }
    } else {
      if (!there_is_not_completed) {
        break;
      }
    }
    i++;
  }
  return menu_data;
}

function f_createVueRouter(routes) {
  const router = new Router({
    // mode: 'hash', // https://router.vuejs.org/api/#mode
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
  });
  return router;
}

function f_createRouteList(menu_data) {
  let added_route_path_list = [];
  let route_list = [];
  // console.log('all_language_options : ', all_language_options);
  for (let lang_index in all_language_options) {
    let lang_label = all_language_options[lang_index]['label'];
    for (let i in menu_data.list) {
      let route_path = '';
      try {
        route_path = menu_data.list[i].route_path[lang_label];
      } catch (err) {}
      let meta_data = {};
      try {
        for (let meta_item in menu_data.list[i].meta) {
          meta_data[meta_item] = menu_data.list[i].meta[meta_item][lang_label];
        }
      } catch (err) {}

      let component_name = '';
      if (menu_data.list[i].component_name) {
        if (menu_data.list[i].component_name && menu_data.list[i].component_name[lang_label]) {
          component_name = menu_data.list[i].component_name[lang_label];
        } else {
          component_name = menu_data.list[i].component_name;
        }
      }

      let route_name = '';
      if (menu_data.list[i].route_name) {
        if (menu_data.list[i].route_name && menu_data.list[i].route_name[lang_label]) {
          route_name = menu_data.list[i].route_name[lang_label];
        } else {
          route_name = menu_data.list[i].route_name;
        }
      }

      let data_unit = '';
      try {
        data_unit = menu_data.list[i].data_unit[lang_label];
      } catch (err) {}
      if (route_path) {
        if (added_route_path_list.indexOf(route_path) === -1) {
          added_route_path_list.push(route_path);
          if (component_name) {
            const new_item = {
              'meta': meta_data,
              'data_unit': data_unit,
              'path': route_path,
              'component': () => import('./wam/views/' + component_name),
              'name': route_name
            };
            route_list.push(new_item);
          } else {
            const new_item = {
              'meta': meta_data,
              'data_unit': data_unit,
              'path': route_path,
              'component': () => import('./wam/views/general'),
              'name': route_name
            };
            route_list.push(new_item);
          }
        }
      }
      if (menu_data.list[i].list && menu_data.list[i].list.length > 0) {
        for (let sub_i in menu_data.list[i].list) {
          let route_path = '';
          try {
            route_path = menu_data.list[i].list[sub_i].route_path[lang_label];
          } catch (err) {}

          let component_name = '';
          if (menu_data.list[i].list[sub_i].component_name) {
            if (menu_data.list[i].list[sub_i].component_name && menu_data.list[i].list[sub_i].component_name[lang_label]) {
              component_name = menu_data.list[i].list[sub_i].component_name[lang_label];
            } else {
              component_name = menu_data.list[i].list[sub_i].component_name;
            }
          }

          let route_name = '';
          if (menu_data.list[i].list[sub_i].route_name) {
            if (menu_data.list[i].list[sub_i].route_name && menu_data.list[i].list[sub_i].route_name[lang_label]) {
              route_name = menu_data.list[i].list[sub_i].route_name[lang_label];
            } else {
              route_name = menu_data.list[i].list[sub_i].route_name;
            }
          }


          let meta_data = {};
          try {
            for (let meta_item in menu_data.list[i].list[sub_i].meta) {
              meta_data[meta_item] = menu_data.list[i].list[sub_i].meta[meta_item][lang_label];
            }
          } catch (err) {}
          let data_unit = '';
          try {
            data_unit = menu_data.list[i].list[sub_i].data_unit[lang_label];
          } catch (err) {}

          if (route_path) {
            if (added_route_path_list.indexOf(route_path) === -1) {
              // console.log('-- ', route_path);
              added_route_path_list.push(route_path);
              if (component_name) {
                const new_item = {
                  'meta': meta_data,
                  'data_unit': data_unit,
                  'path': route_path,
                  'component': () => import('./wam/views/' + component_name),
                  'name': route_name
                };
                route_list.push(new_item);
              } else {
                const new_item = {
                  'meta': meta_data,
                  'data_unit': data_unit,
                  'path': route_path,
                  'component': () => import('./wam/views/general'),
                  'name': route_name
                };
                route_list.push(new_item);
              }
            }
          }
        }
      }
    }
  }
  // console.log(route_list);
  return route_list;
}

function f_getRedirectDashboardRoute(menu_data) {
  let redirect_dashboard_path = '';
  for (let lang_index in all_language_options) {
    let lang_label = all_language_options[lang_index]['label'];
    for (let i in menu_data.list) {
      let is_dashboard = '';
      try {
        is_dashboard = menu_data.list[i].is_dashboard['value'];
      } catch (err) {}
      if (is_dashboard === '1') {
        let route_path = '';
        try {
          route_path = menu_data.list[i].route_path[lang_label];
        } catch (err) {}
        if (route_path) {
          redirect_dashboard_path = route_path;
          break;
        }
      }
      if (menu_data.list[i].list && menu_data.list[i].list.length > 0) {
        for (let sub_i in menu_data.list[i].list) {
          let is_dashboard = '';
          try {
            is_dashboard = menu_data.list[i].list[sub_i].is_dashboard['value'];
          } catch (err) {}
          if (is_dashboard === '1') {
            let route_path = '';
            try {
              route_path = menu_data.list[i].list[sub_i].route_path[lang_label];
            } catch (err) {}
            if (route_path) {
              redirect_dashboard_path = route_path;
              break;
            }
          }

        }
      }
    }
  }
  // console.log(route_list);
  return redirect_dashboard_path;
}


function f_createFrontendSettings(frontend_wdmr) {
  let target_frontend_settings = {
    'status': '',
    'menu_background_color': '',
    'menu_border_bottom_color': '',
    'menu_box_shadow_color': '',
    'menu_parent_item_text_color': '',
    'menu_parent_item_border_bottom_color': '',
    'menu_parent_item_background_color': '',
    'menu_child_item_text_color': '',
    'menu_child_item_border_bottom_color': '',
    'menu_child_item_background_color': '',
    'navbar_application_name_shadow_color': '',
    'navbar_background_color': '',
    'navbar_application_name_color': '',
    'login_background_color': '',
    'login_card_out_background_color': '',
    'login_card_out_shadow_color': '',
    'login_card_in_background_color': '',
    'login_card_in_shadow_color': '',
    'login_buttons_background_color': '',
    'login_inputs_border_color': '',
    'login_loading_background_color': '',
    'login_slogan_text': '',
    'application_name': '',
    'navbar_middle_logo': '',
    'menu_item_text_color': '',
    'meta': '',
    'meta_value': '',
    'wmanager_uri': '',
    'login_function': '',
    'login_function_external': '',
    'page_area_text_color': '',
    'footer_text_color': '',
    'footer_background_color': '',
    'navbar_text_color': '',
    'facebook_link': '',
    'twitter_link': '',
    'instagram_link': '',
    'linkedin_link': '',
    'login_background_image': '',
  };
  // console.log(route_list);
  try {
    target_frontend_settings['status'] = frontend_wdmr['data']['0']['0']['val']['value'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_background_color'] = frontend_wdmr['data']['0']['1']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_border_bottom_color'] = frontend_wdmr['data']['0']['2']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_box_shadow_color'] = frontend_wdmr['data']['0']['3']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_parent_item_text_color'] = frontend_wdmr['data']['0']['4']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_parent_item_border_bottom_color'] = frontend_wdmr['data']['0']['5']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_parent_item_background_color'] = frontend_wdmr['data']['0']['6']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_child_item_text_color'] = frontend_wdmr['data']['0']['7']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_child_item_border_bottom_color'] = frontend_wdmr['data']['0']['8']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_child_item_background_color'] = frontend_wdmr['data']['0']['9']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['navbar_application_name_shadow_color'] = frontend_wdmr['data']['0']['10']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['navbar_application_name_color'] = frontend_wdmr['data']['0']['69']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['navbar_background_color'] = frontend_wdmr['data']['0']['68']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_background_color'] = frontend_wdmr['data']['0']['11']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_card_out_background_color'] = frontend_wdmr['data']['0']['12']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_card_out_shadow_color'] = frontend_wdmr['data']['0']['13']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_card_in_background_color'] = frontend_wdmr['data']['0']['14']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_card_in_shadow_color'] = frontend_wdmr['data']['0']['15']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_buttons_background_color'] = frontend_wdmr['data']['0']['16']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_inputs_border_color'] = frontend_wdmr['data']['0']['17']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_loading_background_color'] = frontend_wdmr['data']['0']['18']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_slogan_text'] = frontend_wdmr['data']['0']['19']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['application_name'] = frontend_wdmr['data']['0']['20']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['navbar_middle_logo'] = frontend_wdmr['data']['0']['21']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['menu_item_text_color'] = frontend_wdmr['data']['0']['22']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['meta'] = frontend_wdmr['data']['0']['23']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['wmanager_uri'] = frontend_wdmr['data']['0']['25']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_function'] = frontend_wdmr['data']['0']['26']['val']['value'];
  } catch (err) {}
  try {
    target_frontend_settings['login_function_external'] = frontend_wdmr['data']['0']['27']['val']['value'];
  } catch (err) {}
  try {
    target_frontend_settings['page_area_text_color'] = frontend_wdmr['data']['0']['70']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['footer_text_color'] = frontend_wdmr['data']['0']['71']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['footer_background_color'] = frontend_wdmr['data']['0']['72']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['navbar_text_color'] = frontend_wdmr['data']['0']['73']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['facebook_link'] = frontend_wdmr['data']['0']['74']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['twitter_link'] = frontend_wdmr['data']['0']['75']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['instagram_link'] = frontend_wdmr['data']['0']['76']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['linkedin_link'] = frontend_wdmr['data']['0']['77']['val'];
  } catch (err) {}
  try {
    target_frontend_settings['login_background_image'] = frontend_wdmr['data']['0']['78']['val'];
  } catch (err) {}
  // console.log('target_frontend_settings : ', target_frontend_settings);
  return target_frontend_settings;
}


/*
  Vue.config.warnHandler = function(msg, vm, trace) {
    // console.log('vue warnHandler: vm => ', vm);
    // console.log('vue warnHandler: msg => ', msg);
    // console.log('vue warnHandler: trace => ', trace.toString());
    if (error_list.indexOf(msg) === -1) {
      error_list.push(msg);
      try {
        let error_data = {
          'codeType': 'frontend',
          'type': 'log',
          'info': trace,
          'sub_type': 'error',
          'typeError': 'frontend',
          'valueError': msg
        };
        // console.log(error_data);
        Vue.http.post(API_BASE_URL + 'log/frontend_log', error_data).then(response => {
          // console.log('response => ', response);
        }, response => {
          // HERE WORKS WHEN WE DONT HAVE AN INTERNET CONNECTION
          console.log('Frontend error sending error. Possible internet connection problem');
        });
      } catch (err) {
        console.log('Vue.config.warnHandler: ', err);
      }
    }
  }
*/

export { store };
