import Vue from 'vue';

import { API_BASE_URL, API_FRONTEND_URL, API_WMANAGER_URL, API_WANALYZER_URL, API_POLESTAR_URL, API_WALGORITHM_URL, API_WRULEENGINE_URL, WDMR_URL } from '@/config';

import ServiceList from '@/wam/config/ServiceList.json';
/*
  [
    {
      "label": "/v1/application/version",
      "data": {
          "general": {
              "route_handler": {"val": "LayerHandlerV1"},
              "route_method": {"val": "POST"},
              "route_view": {"val": "get_application_version"},
              "speed_control": {"val": {"label": "evet", "value": "yes"} },
              "version": {"val": "v1"},
              "module_type": {"val": {"label": "wam", "value": "wam"} },
              "synchronization_type": {"val": {"label": "asenkron", "value": "async"} },
              "url": {"val": "/v1/application/version"},
              "user_type": {"val": {"label": "none", "value": "none"} }
          }
      }
    }
  ]


  frontend_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/frontend/get?' + query;
    } else {
      route = 'v1/frontend/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },

*/

const ServiceFunctions = {};
for (let i in ServiceList) {
  let route_view = ServiceList[i].data.general.route_view.val;
  // console.log('route_view : ', route_view);
  let route_url = ServiceList[i].data.general.url.val;
  let location_name = '';
  try {
    location_name = ServiceList[i].data.general.location_name.val;
  } catch (err) {}
  let use_location = '';
  if (location_name) {
    if (location_name == 'API_BASE_URL') {
      use_location = API_BASE_URL;
    } else if (location_name == 'API_FRONTEND_URL') {
      use_location = API_FRONTEND_URL;
    } else if (location_name == 'API_WMANAGER_URL') {
      use_location = API_WMANAGER_URL;
    } else if (location_name == 'API_WANALYZER_URL') {
      use_location = API_WANALYZER_URL;
    } else if (location_name == 'API_POLESTAR_URL') {
      use_location = API_POLESTAR_URL;
    } else if (location_name == 'API_WALGORITHM_URL') {
      use_location = API_WALGORITHM_URL;
    } else if (location_name == 'API_WRULEENGINE_URL') {
      use_location = API_WRULEENGINE_URL;
    }
  } else {
    use_location = API_BASE_URL;
  }
  if (use_location[use_location.length - 1] === '/' && route_url[0] === '/') {
    use_location = use_location.substring(0, use_location.length - 1);
  }
  // console.log('use_location[use_location.length - 1] ', use_location[use_location.length - 1]);
  // console.log('use_location + route : ', use_location + route_url);

  ServiceFunctions[route_view] = function (query, data) {
    let route = '';
    if (query) {
      route = route_url + '?' + query;
    } else {
      route = route_url + '';
    }
    return Vue.http.post(use_location + route, data);
  };
}

// console.log('ServiceFunctions : ', ServiceFunctions);


export { ServiceFunctions };


